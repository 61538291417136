<template>
  <div>
    <!-- <div>
      <p style="color: #9e9c9b; font-size: 13px; margin-top: -10px; margin-left: 2px">PUBLIC SALES ARE LIVE</p>
      <p style="font-size: 36px; margin-top: 5px"><span style="color: #cd4743">Tron</span> IVY BOYS</p>
    </div> -->

    <div v-if="!isSoldOut" class="center" style="justify-content: flex-start; margin: 10px 0">
      <img src="@/assets/pc/trx.png" style="width: 38.4px; margin-right: 13.6px" />
      <p style="white-space: nowrap">
        <span style="font-size: 27px; color: #000">100TRX </span
        ><span style="font-size: 17px; font-style: italic; color: #828282; font-weight: lighter">per unit</span>
      </p>
    </div>

    <div class="mint-box" v-if="!isSoldOut">
      <!-- total available -->
      <!-- <div style="height: 80px; padding-top: 10px">
        <p style="font-size: 17px">
          Total Available:
          <span v-if="!isConnected">Connect your wallet to see</span>
          <span v-else>{{ max }}</span>
        </p>
        <p style="font-size: 12px; font-style: italic; margin-top: 5px; margin-bottom: 20px">
          Price per unit: 100TRX + Gas Fee
        </p>
      </div> -->
      <!-- num-select -->
      <div class="mint-select center">
        <div class="edit-icon center" :class="{ disabled: minusDisabled }" @click="minusMintNum">&#8722;</div>
        <div class="edit-input center">{{ mintNum }}</div>
        <div class="edit-icon center" :class="{ disabled: addDisabled }" @click="addMintNum">&#43;</div>
      </div>
      <!-- confirm -->
      <div>
        <div class="mint-confirm center" v-if="!isConnected && !isConnecting" @click="connectWallet">
          CONNECT WALLET
        </div>
        <div class="mint-confirm center disabled" v-else-if="isConnecting">CONNECTING..</div>
        <div class="mint-confirm center" :class="{ disabled: !canMint }" v-else-if="!isMinting" @click="triggerMint">
          MINT
        </div>
        <div class="mint-confirm center disabled" v-else>MINTING..</div>
      </div>
    </div>

    <a class="mint-box-soldout" v-else href="https://apenft.io/collections/tron-bored-garbage-club">
      <p style="font-size: 40px; font-weight: bold">SOLD OUT</p>
      <!-- <p style="font-size: 14px; font-weight: bold; margin-top: 5px">BUY ON APENET</p> -->
    </a>
  </div>
</template>
<script>
import { walletMixin } from '../mixin.js';
export default {
  name: 'stage23',
  mixins: [walletMixin],
  props: {
    countdownArr: [],
    stage: Number,
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.countdown-box {
  width: 39px;
  height: 29px;
  background: #cd4743;
  border-radius: 3px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.countdown-divider {
  color: #cd4743;
  margin: 0 5px;
}
.mint-box {
  border-radius: 5px;
  text-align: center;
  color: #000;
  width: 240.7px;
  margin-top: 20px;
}
.mint-box-soldout {
  border-radius: 5px;
  text-align: center;
  color: #000;
  width: 240.7px;
  margin-top: 20px;
  display: block;
  position: relative;
  #soldout-icon {
    width: 60px;
    position: absolute;
    bottom: 10px;
    left: 100px;
  }
}
.mint-select {
  justify-content: space-between;
  .edit-icon {
    width: 45.2px;
    height: 45.2px;
    background-color: #e70000;
    font-size: 20px;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    font-weight: normal;
    font-family: 'Arial Black', sans-serif;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .edit-input {
    font-size: 26px;
    font-family: 'Arial Black', sans-serif;
    color: #fff;
    width: 130px;
    height: 45.2px;
    text-align: center;
    background-color: #e70000;
    border-radius: 33px;
  }
}
.mint-confirm {
  width: 240.7px;
  height: 50.8px;
  border-radius: 13.6px;
  background-color: #e70000;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 16px;
  font-family: 'Arial Black', sans-serif;
  &.disabled {
    opacity: 0.5;
  }
}
</style>
