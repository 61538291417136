<template>
  <div id="pc-container">
    <div id="pc-mask"></div>
    <div id="pc-main" class="center">
      <!-- left logo -->
      <div id="pc-left-logo" class="center">
        <a href="//boredgarbageclub.xyz" target="_blank" class="logo" style="margin-right: 24px">Home</a>
        <a
          href="https://apenft.io/collections/tron-bored-garbage-club"
          target="_blank"
          class="logo market-logo center"
          style="margin-right: 24px"
          ><img style="width: 14px; margin-right: 7.3px" src="@/assets/pc/market-logo.png" />Market</a
        >
      </div>
      <!-- right link -->
      <div id="pc-right-icon" class="center">
        <a href="https://t.me/TronBoredGarbageClub" target="_blank"
          ><img style="width: 34px; margin-right: 14px" src="@/assets/pc/telegram.png"
        /></a>
        <a href="https://twitter.com/T_B_GarbageClub" target="_blank"
          ><img style="width: 34px" src="@/assets/pc/twitter.png"
        /></a>
      </div>

      <div id="pc-center">
        <div id="pc-moto" class="center">
          <img src="@/assets/pc/img.gif" style="width: 217.5px; height: 217.5px" />
        </div>
        <div id="pc-center-right" class="center">
          <stage-23 />
        </div>
      </div>
      <p style="margin-top: 15px; color: #000; letter-spacing: 1.7px">
        All of your NFT will be buried in The Bored Garbage Club on Tron.
      </p>

      <!-- bottom -->
      <div id="pc-bottom" class="center">
        <img src="@/assets/pc/tron.png" style="width: 127.7px; margin-right: 51px" />
        <img src="@/assets/pc/apenft.png" style="width: 108.5px" />
      </div>
    </div>
  </div>
</template>
<script>
import { countdownMixin } from '../mixin';
import Stage23 from './stage23.vue';

export default {
  name: 'mint-pc',
  mixins: [countdownMixin],
  components: {
    stage23: Stage23,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="less">
@font-face {
  font-family: bebas;
  src: url('~@/assets/bebas.ttf');
}
#pc-container {
  width: 100vw;
  min-width: 1080px;
  height: 100vh;
  // background-image: url('~@/assets/pc/back.png');
  // background-size: cover;
  position: relative;
}
#pc-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff1e0;
}
#pc-main {
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
#pc-left-logo {
  position: absolute;
  left: 40px;
  top: 37px;
  font-weight: bold;
  font-size: 20.33px;
  letter-spacing: 1.4px;
  .logo {
    font-weight: bold;
    color: #000;
  }
  .market-logo {
    color: #fff;
    background: #e70000;
    border-radius: 16.9px;
    width: 130px;
    height: 33.9px;
  }
}
#pc-right-icon {
  position: absolute;
  top: 37px;
  right: 40px;
}
#pc-center {
  display: flex;
  width: 547px;
  height: 256px;
  border-radius: 22px;
  background: #fbfbfb;
  padding-left: 22px;
  margin-top: -20px;
  #pc-moto {
  }
  #pc-center-right {
    width: 300px;
    margin-left: 32px;
    justify-content: flex-start;
  }
}
#pc-bottom {
  position: absolute;
  width: 100%;
  bottom: 58px;
  color: #9e9c9b;
  /* font-weight: normal; */
  font-size: 14px;
  letter-spacing: 3.5px;
}

#pc-set {
  position: absolute;
  bottom: 70px;
  border: 1px dashed;
  padding: 0 5px;
  font-size: 13px;
}
</style>
